import React,{useState,useEffect} from "react";
import  Axios from "axios";
import { Hotelapitoken,ApiEndPoint,CustomerDomainName } from '../GlobalData/GlobalData';
import Loading from "../Loading/Loader";
import {Modal, ModalBody } from 'reactstrap'
import { fetchHotels,fetchHotelsSearh, OneWayFlightIndexSearchData,OneWayFlightSearchData } from '../../Redux/Actions/actions';
import { useNavigate,NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer,toast } from 'react-toastify';
import moment from 'moment';
function Footer(){
  const [isLoading, setIsLoading] = useState(false);
  const [userLocation,setUserLocation]=useState([]);
  var token = Hotelapitoken();
  var apiendpoint=ApiEndPoint();
  const navigation=useNavigate();
  const Dispatch=useDispatch();
  useEffect(()=>{
    getUserLocation();
  },[]);
  function getUserLocation() {
    // Fetch user's location data
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041")
      .then(response => response.json())
      .then(data => {
        setUserLocation(data);
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error);
      });
  }

  const Searchhotels = async (name) => {
    setIsLoading(true);
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);
    var destination_name='';
     var country='';
     var lat='';
     var lon='';
     var pin='';
    if(name==='Makkah'){
     destination_name="Makkah";
     country="Saudi Arabia";
     lat=21.4240968;
     lon=39.81733639999999;
     pin="SA";
    }else if(name==='Madinah'){
     destination_name="Madinah";
     country="Saudi Arabia";
     lat=24.4672132;
     lon=39.6024496;
     pin="SA";
    }else if(name==='Riyadh'){
     destination_name="Riyadh";
     country="Saudi Arabia";
     lat=24.7135517;
     lon=46.6752957;
     pin="SA";
    }else if(name==='Jeddah'){
      destination_name="Jeddah";
      country="Saudi Arabia";
      lat=21.5291545;
      lon=39.1610863;
      pin="SA";
     }
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
       "token":token,
      "currency_slc": 'AFN',
      "currency_slc_iso": 'AF',
      "destination_name":destination_name,
      "country":country,
      "lat":  lat,
      "long": lon,
      "pin": pin,
      "cityd":destination_name,
      "country_code": pin,
      "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
      "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
      "slc_nationality":userLocation.country.name,
      "adult": 2,
      "child": 0,
      "room": 1,
      "Adults": [2],
      "children": [0],
      "child_ages1": [],
      "rooms_counter": [1],
      "child_ages2": [],
     
    };
    try {
    
     Dispatch(fetchHotelsSearh(FormData));
      const response = await Axios.post(apiendpoint+'/api/search/hotels/new',FormData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        
        } ,
        
      });
      window.scrollTo(0, 0);
      sessionStorage.removeItem('FlightCheckOut');
      navigation('/hotels');
      Dispatch(fetchHotels(response.data));
      // Handle the API response here
      setIsLoading(false);
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error('Error:', error);
    }
  };
  // **********Excursions Section*************

const getexcursions = async (city) => {
 
};

    return(
    <>
    {isLoading && ( <Loading/>)}
        <div class="uitk-spacing uitk-spacing-padding-block-three uitk-spacing-padding-inlinestart-two uitk-spacing-padding-inlineend-two">
          <h2 class="uitk-heading uitk-heading-4 is-visually-hidden">Additional information about Mufti Travel</h2>
          <footer class="global-navigation-footer">
            <div class="global-navigation-footer-container">
              <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-unset uitk-spacing-padding-inlinestart-six uitk-spacing-padding-blockstart-six  uitk-spacing-padding-small-inlineend-three uitk-spacing-padding-medium-inlineend-three uitk-spacing-padding-large-inlineend-two uitk-spacing-padding-extra_large-inlineend-two">
                <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-spacing uitk-spacing-padding-blockend-two uitk-layout-flex-item uitk-layout-flex-item-flex-basis-full_width" role="list">
                  {/* <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-row-start-by-large" 
                      role="listitem">
                    <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-flex-start uitk-spacing no-bullet uitk-spacing-padding-blockstart-two">
                      <a  >
                        <img aria-label="opens in a new window" src="https://a.travel-assets.com/globalcontrols-service/content/f285fb631b0a976202ef57611c7050e9ef5ca51a/images/EG_Wordmark_blue_RGB.svg" alt="Expedia Group Logo" width="100%" loading="lazy"/>
                      </a>
                    </div>
                  </li> */}
                  <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Need any help?</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                      <div class="footer_inquery_area">
                            <h5>Call 24/7 for any help</h5>
                            <h4> <a href="tel:+966 509 786777">+966 509 786777</a></h4>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                      <div class="footer_inquery_area">
                            <h5>Mail to our support team</h5>
                            <h4> <a href="mailto:support@domain.com">info@haramaynhotels.com</a></h4>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Company</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="About" to="/terms_and_conditions" target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Terms & Conditions</NavLink>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="Jobs" to='/complaint_policy' target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Complaints Policy</NavLink>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="Privacy" to="/privacy_policy" target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Privacy Policy</NavLink>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="Terms of use" to="/faqs"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">FAQs</NavLink>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Hotels</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Makkah')} aria-label="United States of America travel guide"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Makkah</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Madinah')} aria-label="Hotels in United States of America"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Madinah</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Riyadh')} aria-label="Vacation rentals in United States of America"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Riyadh</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Jeddah')} aria-label="Vacation packages in United States of America"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Jeddah</a>
                        </div>
                      </li>
                    </ul>
                  </li>
                  {/* <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Excursions</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>getexcursions('Makkah')} aria-label="Cookies"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Makkah</a>
                        </div>
                      </li>
                     
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>getexcursions('Madinah')} aria-label="Vrbo terms and conditions"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Madinah</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>getexcursions('Riyadh')} aria-label="Get started with One Key™" target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Riyadh</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>getexcursions('Jeddah')} aria-label="Accessibility"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Jeddah</a>
                        </div>
                      </li>
                    </ul>
                  </li> */}
                </ul>
                <div>
                  <hr class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-border-blockstart uitk-spacing-hr" />
                </div>
                <ul class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-column uitk-layout-flex-flex-wrap-wrap no-bullet">
                  {/* <li class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item uitk-layout-flex-item-flex-basis-full_width" role="listitem">
                    <div class="uitk-text uitk-type-start overflow-wrap uitk-type-200 uitk-type-regular uitk-text-default-theme">
                      <span>Mufti Travel, Inc. is not responsible for content on external Web sites. </span>
                    </div>
                  </li> */}
                  <li class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item uitk-layout-flex-item-flex-basis-full_width" role="listitem">
                    <div class="uitk-text uitk-type-start overflow-wrap uitk-type-200 uitk-type-regular uitk-text-default-theme">
                      <span>Copyright © 2024 All Rights Reserved</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
        {/* <footer className="footer-section mt-5">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-6 col-sm-6">
                  <div className="widget text-justify company-intro-widget">
                    <a href="index.html" className="site-logo"><img src={logo} alt="logo"/></a>
                    <p> <span className="web-color">Al-Hijaz</span> is the pioneer tour operation in United Kingdom to offer Hajj and Umrah services from United Kingdom. Make use of our fantastic hajj and umrah packages which are tailored to suit the needs of pilgrims visiting Saudi Arabia for their all important sacred journey.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget course-links-widget">
                    <h5 className="widget-title">Helpfull Links</h5>
                    <ul className="courses-link-list">
                      <li><NavLink className='nav-link' to='/terms_and_conditions'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Terms & conditions</NavLink></li>
                      <li><NavLink className='nav-link' to='/complaint_policy'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Complaints Policy</NavLink></li>
                      <li><NavLink className='nav-link' to='/privacy_policy'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Privacy Policy</NavLink></li>
                      <li><NavLink className='nav-link' to='/faqs'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>FAQs</NavLink></li>
                      <li><NavLink className='nav-link' to='/about-us'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>About Us</NavLink></li>
                      <li><NavLink className='nav-link' to='/contact-us'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Contact Us</NavLink></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="widget newsletter-widget">
                    <h5 className="widget-title">For Special booking request, please call</h5>
                    <div className="footer-newsletter">
                      <h3>0121 777 2522</h3>
                      <p><FontAwesomeIcon icon={faLocationArrow}/>  1a Nansen Road Sparkhill Birmingham B11 4DR UK</p>
                      <div className="social-icons mt-4">
                        <a className="logo_hover" href="https://www.facebook.com/alhijaztoursbirmingham" target="_blank"> <img src={fb} alt="Facebook Logo" width="47px"/></a>
                        <a className="logo_hover ms-4" href="#" target="_blank"> <img src={insta} alt="Insta Logo" height="55px" width="55px"/></a>
                        <a className="logo_hover ms-4" href="https://www.linkedin.com/company/alhijaztours/" target="_blank"> <img src={linkedin} alt="LinkedIn Logo" height="50px" width="50px"/></a>
                        <a className="logo_hover ms-4" href="https://twitter.com/Alhijaztours2" target="_blank"> <img src={twitter} alt="Facebook Logo" width="54px"/></a></div>
                        <p className="mt-2">Al Hijaz tours ltd is a acting agent on behalf of Ruhani Travels atol number 11540</p>

                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                  <div className="col-md-12 col-sm-12 text-sm-left text-center">
                    <span className="copy-right-text">© {moment().format('YYYY')}<span className="web-color"> AlHijaz Tours</span> All Rights Reserved.</span>
                  </div>
              </div>
            </div>
          </div>
        </footer> */}
    </>        
    );
}

export default Footer;