import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLocation,faStar } from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import bgimage from '../../Images/Hotels/bg.jpg'
import image from '../../Images/View Detail/1.jpeg'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import { fetchCheckoutDetail } from '../../Redux/Actions/actions'
import Images from '../../Components/Images/images'
import Layout from '../../Components/Layout/Layout'
import RoomFacilities from '../../Components/Hotel/RoomFacility'
import Axios from 'axios'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
function HotelDetail () {
  const location = useLocation()
  const dispatch = useDispatch()
  const todayDate = new Date()
  var endpoint = ApiEndPoint()
  const apitoken = Hotelapitoken()
  const { index } = location.state || {}
  const [selectedDate, setSelectedDate] = useState(null)
  const [activeButton, setActiveButton] = useState(null)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [showPrice, setShowPrice] = useState(true)
  const [baseCName, setBaseCName] = useState('GBP')
  const [counter, setCounter] = useState(0)

  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([])
  const [displayCount, setDisplayCount] = useState(10)

  const handleViewMore = () => {
    // Show 10 more facilities when the "View More" button is clicked
    setDisplayCount(prevCount => prevCount + 10)
  }
  const navigate = useNavigate()
  const hoteldetail = useSelector(state => state.hotels.hoteldetail)
  const seleectedhotelfromlist2 = useSelector(
    state => state.hotels.hotels.hotels_list
  )
  const seleectedhotelfromlist =seleectedhotelfromlist2.filter(item=>item.hotel_id===hoteldetail.hotel_code)
  let { id } = useParams()
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const ReduxSearchData = useSelector(state => state.hotels.hsearch)

  let Checkin = moment(ReduxSearchData.check_in)
  let checkout = moment(ReduxSearchData.check_out)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const latitude = hoteldetail.latitude
  const longitude = hoteldetail.longitude
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed'

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const handleButtonClick2 = buttonName => {
    setActiveButton(buttonName)
  }
  useEffect(() => {
    let NewArray = []
    let occurrences = {}
    // Iterate over the adults and childs arrays
    for (let i = 0; i < ReduxSearchData.Adults.length; i++) {
      let adult = ReduxSearchData.Adults[i]
      let child = ReduxSearchData.children[i]
      // Create a unique identifier for the pair
      let pairKey = `${adult}-${child}`

      // Update the occurrences object
      occurrences[pairKey] = (occurrences[pairKey] || 0) + 1

      // Only add to the result if the current qty is the maximum
      if (occurrences[pairKey] === 1) {
        NewArray.push({
          adult,
          child,
          qty: occurrences[pairKey],
          status: false
        })
      } else {
        // Update the qty for the existing entry
        NewArray.find(
          entry => entry.adult === adult && entry.child === child
        ).qty = occurrences[pairKey]
      }
    }

    setResult(NewArray)
  }, [ReduxSearchData])
  
  const selectedRoomData = (index, event) => {
    event.preventDefault()
    event.stopPropagation()
    const selectedroom = hoteldetail.rooms_options[index]
    for (let i = 0; i < result.length; i++) {
      if (
        result[i].adult === selectedroom.adults &&
        result[i].child === selectedroom.childs &&
        result[i].qty === selectedroom.rooms_qty
      ) {
        if (result[i].status === false) {
          const key = { rateKey: selectedroom.booking_req_id, index: index }
          const newSelectedRoom = {
            room_rate_key: JSON.stringify(key),
            rooms_qty: selectedroom.rooms_qty,
            index: index
          }
          result[i].status = true
          setSelectedRooms([...selectedRooms, newSelectedRoom])
          setHotelSelectedRooms([...hotelSelectedRooms, key])
        } else {
          if (selectedRooms.some(room => room.index === index)) {
            const updatedSelectedRooms = selectedRooms.filter(
              room => room.index !== index
            )
            const newroom = hotelSelectedRooms.filter(
              item => item.index !== index
            )
            setSelectedRooms(updatedSelectedRooms)
            setHotelSelectedRooms(newroom)
            result[i].status = false
          } else {
            toast.error('Select the room according to the search criteria.', {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        }
      }
    }
  }
  const BookRoom = async () => {
    if (hotelSelectedRooms.length !== 0) {
      if (hotelSelectedRooms.length !== result.length) {
        toast.error(
          'Please Select the room according to the search criteria.',
          {
            position: toast.POSITION.TOP_RIGHT
          }
        )
        return
      }
      const roomdata = {
        rooms_select_data: JSON.stringify(selectedRooms),
        hotel_index: index,
        hotelbeds_select_room: hotelSelectedRooms
      }
      const finaldata = {
        token: apitoken,
        request_data: JSON.stringify(roomdata),
        selected_hotel: JSON.stringify(seleectedhotelfromlist[0]),
        selected_hotel_details: JSON.stringify({
          check_in: ReduxSearchData.check_in,
          check_out: ReduxSearchData.check_out,
          hotel_address: hoteldetail.hotel_address,
          hotel_country: hoteldetail.hotel_country,
          hotel_city: hoteldetail.hotel_city,
          latitude: hoteldetail.longitude,
          longitude: hoteldetail.latitude
        }),
        req_index: index
      }
      setLoading(true)
      try {
        const response = await Axios.post(
          endpoint + '/api/hotels/checkavailability_Live',
          finaldata,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              // Required for CORS support to work
              'Access-Control-Allow-Credentials': true // Required for cookies, authorization headers with HTTPS
              // 'Access-Control-Allow-Headers':
              //   'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
              // "Access-Control-Allow-Methods": "POST, OPTIONS"
            }
          }
        )
        // Handle the API response here
        if (response.data.status === 'error') {
          setLoading(false)
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })
          return
        }
        if(response.data.hotels_data.rooms_list.length===0){
          setLoading(false)
          toast.error('Sorry the room is not available.', {
            position: toast.POSITION.TOP_RIGHT
          })
          return;
        }
        if (response.data !== '') {
          dispatch(fetchCheckoutDetail(response.data.hotels_data))
          navigate('/hotel_checkout')
        }
        setLoading(false)
      } catch (error) {
        // Handle errors here
        setLoading(false)
        console.error('Error:', error)
      }
    } else {
      toast.error('Please Select Room First.', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }
  const renderPrice = (price,currency) => {
    var currencyData= JSON.parse(localStorage.getItem('AllHotelCurr'))
    var selectedcurr=localStorage.getItem('DefaultCurrency')
    if(selectedcurr===currency || currencyData===null){
      setShowPrice(false)
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2);
      }
      return Number(price)
    }else{
      var filterroomcurrdata=currencyData.filter(item=> item.base_code===currency);
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = (Number(gbpprice) * Number(price))
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]
        var baseprice1 = (Number(gbpprice) * Number(price))
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = (Number(gbpprice2) * Number(baseprice1))
      }
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2);
      }
      return baseprice
    }
  };
  const calculateMarkup = price => {
    if (Object.keys(hoteldetail).length !== 0) {
      let markupprice = 0
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if (Number(hoteldetail.admin_markup) !== 0) {
        if (hoteldetail.admin_markup_type === 'Percentage') {
          markupprice = (price * Number(hoteldetail.admin_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(hoteldetail.customer_markup) !== 0) {
        if (hoteldetail.customer_markup_type === 'Percentage') {
          markupprice =
            (finalpricemarkup * Number(hoteldetail.customer_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(finalpricemarkup) % 1 !== 0) {
        return Number(finalpricemarkup).toFixed(2);
      }
      return finalpricemarkup
    }
  }

  return (
    <>
     <ToastContainer />
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className='uitk-spacing uitk-spacing-margin-medium-inline-six'>
           
            <div style={{ marginTop: '1px', backgroundColor: 'white' }}>
              <Images data={hoteldetail.hotel_gallery} />
              <div
                class='uitk-card-content-section uitk-card-content-section-border-block-end uitk-card uitk-card-roundcorner-all custom-css-flat-border-top custom-css-flat-border-bottom uitk-card-has-primary-theme'
                data-stid='uitk-card-content-section-navbar'
                style={{ position: 'sticky' }}
              >
                <div class='uitk-tabs-container'>
                  <ul
                    class='uitk-tabs uitk-tabs-natural background-primary uitk-tabs-jumplink uitk-tabs-with-border'
                    role='list'
                  >
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-1' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-1'
                        onClick={() => handleButtonClick2('section-1')}
                        class='uitk-tab-anchor'
                        aria-current='true'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Overview</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-3' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-3'
                        onClick={() => handleButtonClick('section-3')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Amenities</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-2' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-2'
                        onClick={() => handleButtonClick2('section-2')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Rooms</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    {/* <li role="listitem" className={activeButton === 'section-4' ? 'current' : 'uitk-tab'}>
                    <a  href='#section-4' onClick={() => handleButtonClick('section-4')}  className="uitk-tab-anchor"
                        aria-current="false" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Near By Place</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li> */}
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-5' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-5'
                        onClick={() => handleButtonClick('section-5')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Policies</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-6' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-6'
                        onClick={() => handleButtonClick('section-6')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Review & Rating</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    {/* <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li> */}
                  </ul>
                </div>
                <div class='uitk-layout-position uitk-layout-position-right-zero uitk-layout-position-top-zero uitk-layout-position-bottom-zero uitk-layout-position-absolute mt-1 reserve-room'>
                  <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-justify-content-flex-end uitk-spacing'>
                    <div class='uitk-layout-flex uitk-layout-flex-align-content-center uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0 uitk-spacing uitk-spacing-padding-inlineend-three'>
                      <div data-stid='navbar-quick-reserve-button'>
                        {/* <button data-stid="sticky-button" type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-primary">Reserve a room</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <nav className='navbar view-detail-navbar'>
              <div className='container'>
                <ul>
                  <li>
                    <a
                      href='#section-1'
                      onClick={() => handleButtonClick2('section-1')}
                      className={activeButton === 'section-1' ? 'current' : ''}
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-2'
                      onClick={() => handleButtonClick2('section-2')}
                      className={activeButton === 'section-2' ? 'current' : ''}
                    >
                      Rooms
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-3'
                      onClick={() => handleButtonClick('section-3')}
                      className={activeButton === 'section-3' ? 'current' : ''}
                    >
                      Facilities
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-4'
                      onClick={() => handleButtonClick('section-4')}
                      className={activeButton === 'section-4' ? 'current' : ''}
                    >
                      Near By Place
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-5'
                      onClick={() => handleButtonClick('section-5')}
                      className={activeButton === 'section-5' ? 'current' : ''}
                    >
                      Location
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-6'
                      onClick={() => handleButtonClick('section-6')}
                      className={activeButton === 'section-6' ? 'current' : ''}
                    >
                      Review & Rating
                    </a>
                  </li>
                </ul>
              </div>
            </nav> */}
            </div>
            <div className='container-fluid mt-2 rounded-3'>
              <section
                id='section-1'
                className='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-overflow uitk-spacing uitk-flat-border-top uitk-spacing-margin-blockend-three uitk-spacing-padding-large-inline-six uitk-card-has-primary-theme'
              >
                <h3>{hoteldetail.hotel_name}</h3>
                <div className='row'>
                  <div className='col-md-8'>
                    <i class='awe-icon fa tc fa-check' aria-hidden='true'>
                      <FontAwesomeIcon icon={faLocation} />
                    </i>{' '}
                    {hoteldetail.hotel_address}
                    <div className='row mt-2 mb-2 p-2'>
                      <h6 className='mb-2'>Other Detail</h6>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Hotel Rating
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                               {hoteldetail.stars_rating === '' ? (
                                                <span className='fw-bold'>No Rating</span>
                                              ) : (
                                                Array(hoteldetail.stars_rating)
                                                  .fill(0)
                                                  .map((_, index) => (
                                                    <i key={index} className='fa fa-star'>
                                                      <FontAwesomeIcon icon={faStar} />
                                                    </i>
                                                  ))
                                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Minimum Stay
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {daysBetween} Night
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Check In
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {moment(ReduxSearchData.check_in).format(
                                'DD-MM-YYYY'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Check Out
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {moment(ReduxSearchData.check_out).format(
                                'DD-MM-YYYY'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Country
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {hoteldetail.hotel_country}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              City
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {hoteldetail.hotel_city}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Boards
                            </p>
                            {hoteldetail.hotel_boards.map((item, index) => (
                              <span
                                key={index}
                                className='mt-0 title'
                                style={{ fontSize: '13px' }}
                              >
                                {item.board_name}{' '}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Segments
                            </p>
                            {hoteldetail.hotel_segments.map((item, index) => (
                              <span
                                key={index}
                                className='mt-0 title'
                                style={{ fontSize: '13px' }}
                              >
                                {item}{' '}
                              </span>
                            ))}{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <h5 className='mb-2'>Explore the area</h5>
                    <div style={{ width: '100%' }}>
                      <iframe
                        width='100%'
                        height='250'
                        frameborder='0'
                        scrolling='no'
                        marginheight='0'
                        marginwidth='0'
                        src={mapUrl}
                        className='rounded-4'
                      ></iframe>
                    </div>
                  </div>
                </div>
              </section>
              <div>
                <div class='lazyload-wrapper uitk-spacing uitk-spacing-margin-block-six'>
                  <div class='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme'>
                    <section>
                      <div class='uitk-card-content-section'>
                        <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-small-inline-unset uitk-spacing-padding-medium-inline-unset uitk-spacing-padding-large-inline-three uitk-spacing-padding-extra_large-inline-three uitk-spacing-padding-blockend-three uitk-spacing-padding-blockstart-three about-uit'>
                          <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large sub-about1'>
                            <div class='uitk-layout-flex'>
                              <div class='uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1'>
                                <h2
                                  class='uitk-heading uitk-heading-4'
                                  tabindex='-1'
                                >
                                  About this property
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large sub-about'>
                            <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three sub-about-desc'>
                              <div
                                data-stid='content-item'
                                itemprop='description'
                                class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'
                              >
                                <div class='uitk-layout-flex'>
                                  <div class='uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1'>
                                    <h3
                                      class='uitk-heading uitk-heading-5'
                                      tabindex='-1'
                                    >
                                      {hoteldetail.hotel_name}
                                    </h3>
                                  </div>
                                </div>
                                <div class='uitk-expando-peek'>
                                  <div class='uitk-expando-peek-main uitk-expando-peek-main-active uitk-expando-peek-main-whitespace'>
                                    <div class='uitk-expando-peek-inner display-lines'>
                                      <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid'>
                                        <div class='uitk-layout-grid-item'>
                                          <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                            <div data-stid='content-markup'>
                                              <div class='uitk-text uitk-type-300 uitk-text-default-theme'>
                                                {hoteldetail.hotel_address}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class='uitk-layout-grid-item'>
                                          <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                            <div data-stid='content-markup'>
                                              <div class='uitk-text uitk-type-300 uitk-text-default-theme'>
                                                {hoteldetail.description}{' '}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup">
                                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">You'll also enjoy perks such as:</div>
                                            </div>
                                          </div>
                                        </div> */}
                                        {/* <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup">
                                              <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-margin-blockstart-two" role="list">
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                  <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                  <span class="uitk-typelist-item-child">Free self parking and valet parking</span>
                                                </li>
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                  <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                  <span class="uitk-typelist-item-child">Luggage storage, 1 meeting room, and a 24-hour front desk</span>
                                                </li>
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                  <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                  <span class="uitk-typelist-item-child">Free newspapers, tour/ticket assistance, and smoke-free premises</span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div> */}
                                        {/* <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup">
                                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">Room features</div>
                                            </div>
                                          </div>
                                        </div> */}
                                        {/* <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup"> 
                                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">All guestrooms at {hoteldetail.hotel_name}- ALL Suite Hotel offer comforts such as 24-hour room service and premium bedding, as well as perks like air conditioning and bathrobes. </div>
                                            </div>
                                          </div>
                                        </div> */}
                                        <div class='uitk-layout-grid-item'>
                                          <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                            <div data-stid='content-markup'>
                                              <div class='uitk-text uitk-type-300 uitk-text-default-theme'>
                                                Other amenities include:{' '}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class='uitk-layout-grid-item'>
                                          <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                            <div data-stid='content-markup'>
                                              <ul
                                                class='uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-margin-blockstart-two'
                                                role='list'
                                              >
                                                {hoteldetail.hotel_facilities
                                                  .slice(0, displayCount)
                                                  .map((item, index) => (
                                                    <li
                                                      key={index}
                                                      class='uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent'
                                                      role='listitem'
                                                    >
                                                      <span
                                                        aria-hidden='true'
                                                        class='uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2'
                                                      ></span>
                                                      <span class='uitk-typelist-item-child'>
                                                        {item}
                                                      </span>
                                                    </li>
                                                  ))}
                                              </ul>
                                              {displayCount <
                                                hoteldetail.hotel_facilities
                                                  .length && (
                                                <button
                                                  className='btn btn-success'
                                                  onClick={handleViewMore}
                                                >
                                                  View More
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div class="uitk-expando-peek-control uitk-expando-peek-control-active">
                                    <button type="button" aria-label="See less" analytics="[object Object]" class="uitk-link uitk-expando-peek-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" aria-expanded="true">See less</button>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large sub-about">
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three">
                              <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                    <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Languages</h3>
                                  </div>
                                </div>
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                  <div class="uitk-layout-grid-item">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                      <div class="uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-layout-flex-item">English, Hindi, Urdu</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div class='uitk-spacing bex-homepage-module SimpleContainer px-0 my-2'>
                <div
                  data-testid='one-key-banner-1'
                  id='one-key-banner-1'
                  className='px-0'
                >
                  <div>
                    <div
                      class='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme px-0'
                      data-stid='one-key-message-card'
                    >
                      <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three'>
                        <div class='uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item'>
                          <div class='uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six'>
                            <img
                              class='uitk-mark uitk-mark-landscape-oriented'
                              alt=''
                              src='https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg'
                              id='onekey__standard__always_light'
                            />
                          </div>
                        </div>
                        <div class='uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1'>
                          <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap'>
                            <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1'>
                              <div class='uitk-spacing uitk-spacing-padding-inlineend-two'>
                                <h2 class='uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme'>
                                  You'll always get our best prices!
                                </h2>
                              </div>
                            </div>
                            {/* <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                          <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                            <a href="https://www.expedia.com/login?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UIPrimaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset">Sign in</a>
                                            <a href="https://www.expedia.com/welcome-one-key?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UITertiaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-tertiary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-two uitk-button-tertiary-inverse">Create an account</a>
                                          </div>
                                      </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section id='section-2' className='mt-3'>
                <h2 class='uitk-heading uitk-heading-4 mb-2' tabindex='-1'>
                  Choose Your Room
                </h2>
                <HotelSearch />
                {/* <div class="uitk-spacing uitk-spacing-margin-block-three">
                <div class="uitk-layout-grid uitk-layout-grid-align-items-center uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid room-tabs" >
                  <span class="is-visually-hidden">Available filters for rooms</span>
                  <div class="uitk-pill-container">
                    <div class="uitk-pill">
                      <input id="ALLROOMS" aria-checked="true" type="checkbox" class="uitk-pill-standard is-visually-hidden" value="true" checked="" />
                      <label class="uitk-pill-content" for="ALLROOMS" aria-label="All rooms">
                        <span class="uitk-pill-text">All rooms</span>
                      </label>
                    </div>
                    <div class="uitk-pill">
                      <input id="1BED" aria-checked="false" type="checkbox" class="uitk-pill-standard is-visually-hidden" value="false" />
                      <label class="uitk-pill-content" for="1BED" aria-label="1 bed">
                        <span class="uitk-pill-text">1 bed</span>
                      </label>
                    </div>
                    <div class="uitk-pill">
                      <input id="2BEDS" aria-checked="false" type="checkbox" class="uitk-pill-standard is-visually-hidden" value="false"/>
                      <label class="uitk-pill-content" for="2BEDS" aria-label="2 beds">
                        <span class="uitk-pill-text">2 beds</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-4 uitk-text uitk-type-300 uitk-text-white-space-nowrap uitk-text-default-theme uitk-layout-grid-item">Showing 2 of 2 rooms</div>
                </div>
              </div> */}
                <div className='row'>
                  {hoteldetail.rooms_options.map((item, index) => (
                    <div key={index} className='col-md-4 pt-2 pb-2'>
                      <div
                        class='uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-overflow uitk-card-has-primary-theme'
                        data-stid='property-offer-1'
                      >
                        <div class='uitk-layout-flex-item'>
                          <div>
                            <span>
                              <div class='uitk-gallery-carousel uitk-rounded-border-top'>
                                <div
                                  style={{ height: '15em', overflow: 'hidden' }}
                                >
                                  {item.rooms_images &&
                                  item.rooms_images.length > 0 ? (
                                    <img
                                      className='room-card-image'
                                      src={item.rooms_images[0]}
                                      alt={item.room_name}
                                    />
                                  ) : (
                                    <img src={noImage} alt='Default Image' />
                                  )}
                                </div>
                              </div>
                            </span>
                            {hoteldetail.hotel_provider ==='Custome_hotel' && (
                              <div class='uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-right-zero uitk-layout-position-zindex-layer2 uitk-layout-position-absolute'>
                                <div data-stid='outlined-save-button'>
                                  <div class='favorite-button-wrapper'>
                                    <button
                                      type='button'
                                      aria-label='Save Swissôtel Al Maqam Makkah to a trip'
                                      class='favorite-button favorite-button-button-no-label'
                                    >
                                      HH
                                    </button>
                                  </div>
                               </div>
                            </div>
                            )}
                          </div>
                          <div class='uitk-spacing uitk-spacing-padding-blockstart-three uitk-spacing-padding-blockend-two uitk-spacing-padding-inline-three'>
                            <div class='uitk-spacing uitk-spacing-padding-small-blockend-half'>
                              <h3 class='uitk-heading uitk-heading-6'>
                                {item.room_name}
                              </h3>
                            </div>
                            <ul className='room_facility mt-2'>
                              {item.rooms_facilities &&
                              item.rooms_facilities.length > 0 ? (
                                <RoomFacilities item={item} />
                              ) : (
                                <li>No facilities available</li>
                              )}
                            </ul>
                            {item.request_type === '1' && (
                              <div className='room-request'>
                                <h6>Room on Request</h6>
                              </div>
                            )}
                            <div class='d-flex justify-content-between mt-2'>
                              <h6>{item.adults} Adults </h6>
                              <h6> {item.childs} children</h6>
                              <h6> {item.rooms_qty} Rooms</h6>
                            </div>
                            <div
                              class='uitk-layout-grid mt-2 uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid uitk-spacing uitk-spacing-margin-block-two'
                              data-stid='highlightedMessages-321661323'
                            >
                              <div class='uitk-layout-flex'>
                                <div class='uitk-spacing uitk-spacing-padding-blockstart-half'>
                                  {item.cancliation_policy_arr &&
                                  item.cancliation_policy_arr.length > 0 ? (
                                    new Date(
                                      item.cancliation_policy_arr[0].from_date
                                    ) > todayDate ? (
                                      <button
                                        type='button'
                                        class='uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium'
                                      >
                                        <div
                                          style={{ color: 'green' }}
                                          class='uitk-text uitk-type-300 uitk-text-default-theme'
                                        >
                                          No cancellation fee before{' '}
                                          {moment(
                                            item.cancliation_policy_arr[0]
                                              .from_date
                                          ).format('DD-MM-YYYY')}{' '}
                                          (property local time)
                                        </div>
                                      </button>
                                    ) : (
                                      <button
                                        type='button'
                                        class='uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium'
                                      >
                                        <div
                                          style={{ color: 'red' }}
                                          class='uitk-text uitk-type-300 uitk-text-default-theme'
                                        >
                                          Non-Refundable
                                          {/* <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                      </svg> */}
                                        </div>
                                      </button>
                                    )
                                  ) : (
                                    <button
                                      type='button'
                                      class='uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium'
                                    >
                                      <div
                                        style={{ color: 'red' }}
                                        class='uitk-text uitk-type-300 uitk-text-default-theme'
                                      >
                                        Non-Refundable
                                        {/* <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                      </svg> */}
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='uitk-card-content-section uitk-layout-flex-item-align-self-stretch uitk-layout-flex-item'>
                          <div class='uitk-spacing uitk-spacing-margin-three'>
                            <div>
                              <div class=' uitk-layout-flex-align-items-flex-end uitk-layout-flex-justify-content-space-between'>
                                <div class='uitk-layout-flex-item'>
                                  <div>
                                    <div data-stid='price-summary'>
                                      <div
                                        class=' uitk-layout-flex-flex-direction-column'
                                        data-test-id='price-summary'
                                      >
                                        <div>
                                          <div
                                            class=' uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap'
                                            data-test-id='price-summary-message-line'
                                          >
                                            <div class='uitk-spacing text-center uitk-spacing-padding-block-half'>
                                              
                                              <span aria-hidden='true'>
                                                <div class='uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme'>
                                                  {showPrice ? (
                                                    <h6
                                                      style={{
                                                        fontFamily:
                                                          '-webkit-body',
                                                        fontSize: '18px'
                                                      }}
                                                    >
                                                      {CurrencyRates ===
                                                      undefined
                                                        ? baseCName
                                                        : CurrencyRates.selectedcurrency}{' '}
                                                      {renderPrice(
                                                        calculateMarkup(
                                                          item.room_Promotions_Exist ==='1'?  item.rooms_selling_price_Promotion :item.rooms_total_price
                                                        ),hoteldetail.hotel_curreny
                                                      )}
                                                      {item.room_Promotions_Exist ==='1'?(
                                                         <sub style={{color:'#808080ad'}}><del>{renderPrice(calculateMarkup(
                                                          item.rooms_total_price
                                                       ),hoteldetail.hotel_curreny)}</del></sub>
                                                      ):(null)}
                                                    </h6>
                                                  ) : (
                                                    <h6
                                                      style={{
                                                        fontFamily:
                                                          '-webkit-body',
                                                        fontSize: '18px'
                                                      }}
                                                    >
                                                      {
                                                        hoteldetail.hotel_curreny
                                                      }{' '}
                                                      {calculateMarkup(
                                                         item.room_Promotions_Exist ==='1'?  item.rooms_selling_price_Promotion :item.rooms_total_price
                                                      )}
                                                      {item.room_Promotions_Exist ==='1'?(
                                                         <sub style={{color:'#808080ad'}}><del>{calculateMarkup(
                                                          item.rooms_total_price
                                                       )}</del></sub>
                                                      ):(null)}
                                                    </h6>
                                                  )}
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            class=' uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap'
                                            data-test-id='price-summary-message-line'
                                          >
                                            <div class='uitk-text text-center uitk-type-start uitk-type-200 uitk-text-default-theme'>
                                              Price For {daysBetween} Nights
                                            </div>
                                          </div>
                                          {/* <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                              <div class="uitk-text uitk-type-start uitk-type-200 uitk-text-default-theme">includes taxes &amp; fees</div>
                                            </div> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class='form-group text-center'
                                      data-toggle='buttons'
                                    >
                                      <label
                                        onClick={event =>
                                          selectedRoomData(index, event)
                                        }
                                        class='btn btn-default mt-2 select-room--checkbox primary'
                                      >
                                        <i class='fa fa-fw'></i>
                                        <input
                                          id={index}
                                          autoComplete='off'
                                          className='room-check'
                                          type='checkbox'
                                          checked={selectedRooms.some(
                                            room => room.index === index
                                          )}
                                          onChange={() => {}} // Add an empty onChange handler to prevent warnings
                                        />
                                        Select Room
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {/* <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-end uitk-layout-flex-flex-direction-column uitk-layout-flex-gap-two uitk-layout-flex-item-align-self-flex-end uitk-layout-flex-item">
                                  <div class="uitk-layout-flex uitk-layout-flex-justify-content-flex-end">
                                    <button type="submit" data-stid="submit-hotel-reserve" role="link" class="uitk-button uitk-button-medium uitk-button-primary" onClick={BookRoom}>
                                      <span aria-hidden="true">Reserve</span>
                                      <span class="is-visually-hidden">Reserve Deluxe Room</span>
                                    </button>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className='col-md-4 room-detail-center item-from'>
                            <h6 className='tc'>Meal Type</h6>
                            <h6>{item.board_id}</h6>
                          </div> */}
                      {/* <div className='col-md-4 text-center item-from'>
                            <h6 className='tc'>Room Capacity</h6>
                            <h6>
                              {' '}
                              {item.adults} Adults , {item.childs} children
                            </h6>
                            <h6> {item.rooms_qty} Rooms</h6>
                          </div> */}
                    </div>
                  ))}
                </div>
              </section>
              {/* <div class="uitk-spacing uitk-spacing-margin-block-six">
              <div class="lazyload-wrapper ">
                <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-overflow uitk-card-padded uitk-card-has-primary-theme">
                  <section id='section-3'>
                    <div class='property-highlights'>
                      <h3>Property highlights</h3>
                      <div class='property-highlights__content'>
                        <div class='row'>
                          {hoteldetail.hotel_facilities===null || hoteldetail.hotel_facilities==='' || hoteldetail.hotel_facilities.length===0 ? (
                            <h5> No Facilities Available</h5>
                          ):(
                            <>
                            {hoteldetail.hotel_facilities.map((item, index) => (
                              <div key={index} class='col-md-3 col-6'>
                                <div class='item'>
                                  <i
                                    style={{ color: '#d39d00' }}
                                    class='awe-icon fa fa-check'
                                    aria-hidden='true'
                                  >
                                    <FontAwesomeIcon icon={faCheck} />
                                  </i>{' '}
                                  <span>{item}</span>
                                </div>
                              </div>
                            ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div> */}
              {/* <div>
              <div id="Amenities" class="uitk-spacing uitk-spacing-margin-block-six">
                <div class="lazyload-wrapper "></div>
              </div>
              <div id="Accessibility" class="uitk-spacing">
                <div class="lazyload-wrapper ">
                  <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-spacing uitk-card-roundcorner-all uitk-spacing-padding-block-six uitk-spacing-padding-small-inline-three uitk-spacing-padding-large-inline-six uitk-card-has-primary-theme">
                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-access">
                      <div class="uitk-layout-columns uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                        <h2 class="uitk-heading uitk-heading-4 uitk-spacing uitk-spacing-padding-inlineend-two">Accessibility</h2>
                        <p class="uitk-paragraph uitk-paragraph-2 uitk-spacing uitk-spacing-padding-blockstart-one uitk-spacing-padding-blockend-three">If you have requests for specific accessibility needs, please contact the property using the information on the reservation confirmation received after booking.</p>
                      </div>
                      <div class="uitk-layout-columns uitk-layout-columns-gap-twelve uitk-layout-columns-minwidth-half_width uitk-layout-grid-item">
                        <div class="uitk-layout-flex uitk-layout-flex-gap-two">
                          <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inlineend-one uitk-layout-flex-item" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m21.61 21.41-.47-1.1a.49.49 0 0 0-.16-.2.4.4 0 0 0-.23-.06h-.84V7.4a.4.4 0 0 0-.12-.28.38.38 0 0 0-.29-.13h-3.75V2.41a.4.4 0 0 0-.12-.28.38.38 0 0 0-.29-.13H4.5a.38.38 0 0 0-.3.13.4.4 0 0 0-.11.28v17.64h-.84a.4.4 0 0 0-.23.07.49.49 0 0 0-.16.18l-.47 1.11a.44.44 0 0 0-.03.2c0 .08.03.14.07.2a.38.38 0 0 0 .33.2h18.48a.38.38 0 0 0 .33-.2.36.36 0 0 0 .07-.2c0-.06 0-.13-.03-.2zM9.09 17h-2.5v-2.5h2.5V17zm0-5h-2.5V9.5h2.5V12zm0-5h-2.5V4.5h2.5V7zm4.16 12.77h-2.5V14.5h2.5v5.27zm0-7.77h-2.5V9.5h2.5V12zm0-5h-2.5V4.5h2.5V7zm4.16 10h-2.5v-2.5h2.5V17zm0-5h-2.5V9.5h2.5V12z"></path>
                          </svg>
                          <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-padding-inlineend-two" tabindex="-1">Common areas</h3>
                        </div>
                        <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-padding-inlinestart-six uitk-spacing-padding-blockend-four uitk-spacing-padding-blockstart-two" role="list">
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">Wheelchair accessible (may have limitations)</div>
                              <meta itemprop="value" />
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Accessible parking space</div>
                            <meta itemprop="value"/>
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Elevator</div>
                            <meta itemprop="value"/>
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Steps to reach entrance</div>
                            <meta itemprop="value"/>
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Wheelchair-accessible business center</div>
                            <meta itemprop="value"/>
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Wheelchair-accessible path to elevator</div>
                            <meta itemprop="value"/>
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Wheelchair-accessible restaurant</div>
                            <meta itemprop="value"/>
                          </li>
                        </ul>
                      </div>
                      <div class="uitk-layout-columns uitk-layout-columns-gap-twelve uitk-layout-columns-minwidth-half_width uitk-layout-grid-item">
                        <div class="uitk-layout-flex uitk-layout-flex-gap-two">
                          <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inlineend-one uitk-layout-flex-item" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 19V4h-4V3H5v16H3v2h12V6h2v15h4v-2h-2zm-6 0H7V5h6v14zm-3-8h2v2h-2v-2z"></path>
                          </svg>
                          <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-padding-inlineend-two" tabindex="-1">Rooms</h3>
                        </div>
                        <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-padding-inlinestart-six uitk-spacing-padding-blockend-four uitk-spacing-padding-blockstart-two" role="list">
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Area rug in room</div>
                            <meta itemprop="value"/>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              {/* <div>
              <div id="section-5" class="uitk-spacing uitk-spacing-margin-block-six">
                <div class="lazyload-wrapper ">
                  <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                    <section>
                      <div class="uitk-card-content-section">
                        <div id="Policies" tabindex="-1">
                          <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-small-inline-unset uitk-spacing-padding-medium-inline-unset uitk-spacing-padding-large-inline-three uitk-spacing-padding-extra_large-inline-three uitk-spacing-padding-blockend-three uitk-spacing-padding-blockstart-three policy-uitk">
                            <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large">
                              <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                  <h2 class="uitk-heading uitk-heading-4" tabindex="-1">Policies</h2>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-expando-peek uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large sub-policy">
                              <div class="uitk-expando-peek-main">
                                <div class="uitk-expando-peek-inner" data-items-to-display="0">
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk1">
                                      
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Special check-in instructions</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">This property offers transfers from the airport; guests must contact the property with arrival details before travel, using the contact information on the booking confirmation</div>
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Front desk staff will greet guests on arrival</div>
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">At check-in, guests must provide a record of full COVID-19 vaccination</div>
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">COVID-19 vaccination record requirement applies to all guests aged 12 and up; guests must have received complete COVID-19 vaccination at least 14 days prior to check-in</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Access methods</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Staffed front desk</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" itemprop="petsAllowed" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Pets</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">No pets or service animals allowed</div>
                                            <meta itemprop="petsAllowed" content="No pets or service animals allowed" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" itemprop="paymentAccepted" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Property payment types</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div data-stid="content-image" class="uitk-spacing uitk-spacing-margin-blockstart-two">
                                              <div class="uitk-layout-flex uitk-layout-flex-gap-half uitk-layout-flex-flex-wrap-wrap">
                                                <div class="LazyLoad is-visible uitk-layout-flex-item" style={{height: '64px'}}>
                                                  <img src="https://a.travel-assets.com/dms-svg/payments/cards-cc_master_card.svg" alt="Mastercard" height="100%"/>
                                                  <meta itemprop="paymentAccepted" content="Mastercard"/>
                                                </div>
                                                <div class="LazyLoad is-visible uitk-layout-flex-item" style={{height: '64px'}}>
                                                  <img src="https://a.travel-assets.com/dms-svg/payments/cards-cc_visa.svg" alt="Visa" height="100%"/>
                                                  <meta itemprop="paymentAccepted" content="Visa"/>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div> */}
              {/* <div>
              <div id="Policies" class="uitk-spacing uitk-spacing-margin-block-six">
                  <div class="lazyload-wrapper ">
                    <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                      <section>
                        <div class="uitk-card-content-section">
                          <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-small-inline-unset uitk-spacing-padding-medium-inline-unset uitk-spacing-padding-large-inline-three uitk-spacing-padding-extra_large-inline-three uitk-spacing-padding-blockend-three uitk-spacing-padding-blockstart-three policy-uitk">
                            <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large">
                              <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                    <h2 class="uitk-heading uitk-heading-4" tabindex="-1">Important information</h2>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-expando-peek uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large sub-policy">
                              <div class="uitk-expando-peek-main">
                                <div class="uitk-expando-peek-inner" data-items-to-display="0">
                       
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">You need to know</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Extra-person charges may apply and vary depending on property policy</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Government-issued photo identification and a credit card, debit card, or cash deposit may be required at check-in for incidental charges</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Special requests are subject to availability upon check-in and may incur additional charges; special requests cannot be guaranteed</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">This property accepts credit cards and cash</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Cashless transactions are available</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Long-term renters welcome</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">We should mention</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Only registered guests are allowed in the guestrooms</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">No pets and no service animals are allowed at this property</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                  </div>
                </div>
              </div>
            </div> */}
              {/* <div>
              <div class="lazyload-wrapper">
                <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-spacing uitk-spacing-padding-block-six uitk-spacing-padding-large-inline-six uitk-spacing-padding-extra_large-inline-six uitk-card-has-primary-theme">
                    <div class="uitk-layout-grid uitk-layout-grid-has-areas uitk-layout-grid-has-areas-by-medium uitk-layout-grid-has-areas-by-large uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns uitk-layout-grid-has-rows-by-large uitk-layout-grid-has-rows-by-medium uitk-layout-grid-has-rows uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-faq">
                      <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-end uitk-layout-grid-item-has-row-start uitk-layout-grid-item-has-row-end uitk-sub-faq1">
                        <h2 class="uitk-heading uitk-heading-4 uitk-spacing uitk-spacing-margin-blockend-four">Frequently asked questions</h2>
                      </div>
                      <div class="uitk-expando-peek uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-end uitk-layout-grid-item-has-row-start uitk-layout-grid-item-has-row-end uitk-sub-faq">
                        <div class="uitk-expando-peek-main">
                          <div class="uitk-expando-peek-inner" data-items-to-display="0">
                            <section class="uitk-expando-list">
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">Is Heritage Luxury Suites- ALL Suite Hotel pet-friendly? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>No, pets are not allowed at this property. </div>
                                  </div>
                                </div>
                              </details>
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">How much is parking at Heritage Luxury Suites- ALL Suite Hotel? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>Self parking and valet parking are free at this property. </div>
                                  </div>
                                </div>
                              </details>
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">What time is check-in at Heritage Luxury Suites- ALL Suite Hotel? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>Check-in start time: 2 PM; Check-in end time: 6 PM. Late check-in is available during limited hours. </div>
                                  </div>
                                </div>
                              </details>
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">What time is check-out at Heritage Luxury Suites- ALL Suite Hotel? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>Check-out is at noon. </div>
                                  </div>
                                </div>
                              </details>
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">Does Heritage Luxury Suites- ALL Suite Hotel provide a shuttle to the airport? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>Yes, there's a free airport shuttle that runs on request. </div>
                                  </div>
                                </div>
                              </details>
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">Where is Heritage Luxury Suites- ALL Suite Hotel located? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>Located in Gulberg, this hotel is within a 10-minute walk of M.M. Allam Road and Liberty Market. Gaddafi Stadium and Fortress Stadium are also within 6 mi (10 km). </div>
                                  </div>
                                </div>
                              </details>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              <div></div>
            </div>
          </div>
        </Layout>
      )}
      <div id='mybutton'>
        <button
          onClick={BookRoom}
          class='btn feedback  select-styling search-btn1 mt-2 detail-view-btn'
        >
          Book Now
        </button>
      </div>
    </>
  )
}

export default HotelDetail
